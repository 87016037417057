* {
  box-sizing: border-box;
}

html {
  background: #000;
  font-size: 13.333px;
  line-height: 16px;
  user-select: none;
  touch-action: none;
}

body {
  margin: 0;
  font-family: "custom", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img,
canvas {
  display: block;
  margin: 0 auto;
  user-select: none;
}

.buttonImg {
  pointer-events: auto;
  user-select: none;
}
input {
  user-select: none;
}
@media (hover: hover) {
  .buttonImg:hover {
    background-color: #cccccc !important;
    background-blend-mode: multiply;
  }
}
.buttonImg.selected {
  position: relative;
  background-color: cyan !important;
  background-blend-mode: multiply;
  cursor: default;
}
.buttonImg.disabled {
  position: relative;
  background-color: #888 !important;
  background-blend-mode: multiply;
  cursor: default;
}
.cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}
.text-hover:hover {
  background: #ddd;
}
@media (hover: hover) {
  label:hover .cover {
    background-color: #cccccc !important;
    mix-blend-mode: multiply;
  }
}
a {
  color: inherit;
}
